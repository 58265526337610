import React from "react"

const Home = () =>{
    return(
        <div className="content">
            <p>I'm Michael Delgado, and this is my portfolio/blog</p>
            <p>
                This site was created using react, and the react router,
                as well as some features of AWS Lambda
            </p>
        </div>
    )
}

export default Home